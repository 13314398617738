import React from "react";
import { styled } from "../../stitches.config";
import { bodyLarge } from "../commonStyles/Fonts";


interface HeadingUnderTitleProps {
    underTitle?: string;
}

const HeadingUnderTitle: React.FC<HeadingUnderTitleProps> = ({
    underTitle,
    ...props
}) => {
    return (
        underTitle ?
            <UnderTitle className={bodyLarge()}>{underTitle}</UnderTitle>
            :
            null
    );
};

const UnderTitle = styled("p", {
    maxWidth: "620px",
    margin: 0,
    color: "$grayscale100",
    marginBottom: "24px",
    "@bp4": {
        marginTop: "-16px",
        marginBottom: "40px",
    }
});

export default HeadingUnderTitle;
