import React from "react";

import Editor from "../../components/composition-components/box/EditorArticle";
import AutofillEditor from "../../components/composition-components/box/AutofillEditor";
import EditorWrapper from '../../components/EditorWrapper';
import EmptyArticle from "../../components/common/EmptyArticle";

import Heading from './Heading';
import HeadingUnderTitle from "./HeadingUnderTitle"

const BHeading = props => {
  const article = EmptyArticle();

  const { settings } = props;
  const style = settings?.b1?.style;
  const hiddenDots = (parseInt(settings.b1?.hiddenDots) === 1);

  return (
    <div className={props.classes + ' container'} {...props.events} >
      <EditorWrapper {...props} editor={props.autofill ? AutofillEditor : Editor} />
      <Heading
        title={settings.b1.title_normal}
        url={settings.b1.link}
        specialHeadingStyle={(style == "center-white" || style == "center-black") ? "specialDots" : undefined}
        specialHeading
        style={style}
        hiddenDots={hiddenDots}
      />
      <HeadingUnderTitle
        underTitle={settings.b1.under_title}
      />
    </div>
  )
}

export default BHeading;